// @flow

export const TITLEBAR_HEIGHT = "52px";
export const JQL_HEIGHT = "140px";
export const CREDENTIALS_HEIGHT = "120px";

export const COLOR = {
  SHADES: {
    LIGHTEST: "#F2F2F2",
    LIGHTER: "#D2D2D2",
    LIGHT: "#BBBBBB",
    DARK: "#999999",
    DARKER: "#5F5F5F",
    DARKEST: "#2D2D2D"
  },
  PRIMARY: "#3B86F2"
};
