// @flow

export const LS_CODE_SNIPPET_KEY = "sct-code-snippet";
export const LS_JQL_SNIPPET_KEY = "sct-jql-snippet";
export const LS_CREDENTIALS_KEY = "sct-credentials";
export const LS_PASSWORD_KEY = "sct-password";

export const PROTECTED = [LS_PASSWORD_KEY];

export const DEFAULT_JQL = "status != Done AND updated > startOfMonth()";
