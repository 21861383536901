// @flow

import * as React from "react";
import styled from "styled-components";
import { TITLEBAR_HEIGHT } from "../style";

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 50%;
  top: 0;
`;

const IFrame = styled.iframe`
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
`;

export function Editor() {
  return (
    <Container>
      <IFrame height="100%" width="100%" src="editor.html" />
    </Container>
  );
}
