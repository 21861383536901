// @flow
import * as React from "react";
import styled from "styled-components";
import { COLOR } from "../style";

const PanelContainer = styled.div`
  width: 100%;
  padding: 22px;
  background: ${p => (p.dark ? COLOR.SHADES.DARKEST : COLOR.SHADES.LIGHTEST)};
  position: relative;
  height: ${p => (p.open ? "auto" : 0)};
  padding-bottom: ${p => (p.open ? "28px" : "8px")};
  overflow: hidden;
`;

const Title = styled.h5`
  margin: 0;
  padding: 0;
  font-size: 10px;
  text-transform: uppercase;
  color: ${p => (p.dark ? COLOR.SHADES.LIGHT : COLOR.SHADES.DARK)};
  margin: -14px 0 0 0;
  padding-bottom: 12px;
`;

const Handle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${p => (p.open ? "14px" : "30px")};

  &:hover {
    background: ${p =>
      p.dark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)"};

    &:after {
      opacity: 0.8;
    }
  }

  &:after {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    left: 50%;
    top: 50%;
    border-right: 2px solid ${COLOR.SHADES.DARK};
    border-bottom: 2px solid ${COLOR.SHADES.DARK};
    transform: ${p => (p.open ? "scaleY(-1) translateY(90%)" : "")}
      translateY(-70%) translateX(-50%) rotate(45deg);
    opacity: 0.4;
  }
`;

type Props = { children?: React.Node, dark?: boolean, title?: string };

export class Panel extends React.Component<Props, { open: boolean }> {
  state = { open: true };

  onToggleOpen = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { children, dark = false, title } = this.props;

    return (
      <PanelContainer dark={dark} open={this.state.open}>
        {title ? <Title dark={dark}>{title}</Title> : null}
        {children}
        <Handle
          dark={dark}
          open={this.state.open}
          onClick={this.onToggleOpen}
        />
      </PanelContainer>
    );
  }
}
