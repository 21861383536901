// @flow

import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { create } from "domain";
import { Editor } from "../Editor/frame";
import { Preview } from "../Preview";
import { TITLEBAR_HEIGHT } from "../style";

import LogoSvg from "./sct-logo-opt.svg";

const Logo = styled(LogoSvg)`
  max-height: 100%;
  padding: 5px;
  margin-left: 8px;
  max-width: ${TITLEBAR_HEIGHT};
`;

const StyleReset = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, input {
    margin: 0;
    font-family: 'Roboto Mono', sans-serif;
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: ${TITLEBAR_HEIGHT};
`;

const Titlebar = styled.div`
  background: white;
  height: ${TITLEBAR_HEIGHT};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

export default function App() {
  return (
    <div>
      <StyleReset />
      <Titlebar>
        <Logo />
      </Titlebar>
      <Container>
        <Editor />
        <Preview />
      </Container>
    </div>
  );
}
