// @flow

import * as React from "react";
import styled from "styled-components";
import {
  TITLEBAR_HEIGHT,
  JQL_HEIGHT,
  CREDENTIALS_HEIGHT,
  COLOR
} from "../style";
import { JQLInput } from "./jql";
import { Credentials } from "./credentials";

const Container = styled.div`
  position: absolute;
  overflow: hidden;
  bottom: 0;
  right: 0;
  left: 50%;
  top: 0;
  display: flex;
  flex-direction: column;
`;

const IFrameContainer = styled.div`
  position: relative;
  height: 100%;
`;

const IFrame = styled.iframe`
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const PreviewLink = styled.a`
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-decoration: none;
  padding: 6px 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  background: ${COLOR.PRIMARY};
  border-top-left-radius: 10px;
  position: absolute;
  z-index: 9998;
  bottom: 0;
  right: 0;
`;

const InputPanels = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
  z-index: 9999;
`;

export function Preview() {
  return (
    <Container>
      <InputPanels>
        <Credentials />
        <JQLInput />
      </InputPanels>
      <IFrameContainer>
        <PreviewLink target="_blank" href="preview.html">
          Preview &amp; Print
        </PreviewLink>
        <IFrame height="100%" width="100%" src="preview.html" />
      </IFrameContainer>
    </Container>
  );
}
