// @flow
import * as React from "react";
import styled from "styled-components";
import { LS_CREDENTIALS_KEY, LS_PASSWORD_KEY } from "../config";
import { JQL_HEIGHT, CREDENTIALS_HEIGHT, COLOR } from "../style";
import { Panel } from "./panel";

const CredentialsContainer = styled.div`
  input {
    display: block;
    color: white;
    background: none;
    border: none;
    font-size: 14px;
    width: 100%;
    padding: 5px 0;
    &:focus {
      outline: none;
    }
  }
`;

type State = { user: string, host: string, pass: string };

export class Credentials extends React.Component<{}, State> {
  constructor() {
    super();
    const creds = localStorage.getItem(LS_CREDENTIALS_KEY);
    const password = sessionStorage.getItem(LS_PASSWORD_KEY);

    this.state = {
      user: "",
      host: "",
      pass: ""
    };

    if (creds) {
      this.state = JSON.parse(creds);
    }
    if (password) {
      this.state.pass = password;
    }
  }

  updateStorage = () => {
    const { user, host, pass } = this.state;
    const local = JSON.stringify({ user, host });

    localStorage.setItem(LS_CREDENTIALS_KEY, local);
    sessionStorage.setItem(LS_PASSWORD_KEY, pass);
  };

  onChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.updateStorage);
  };

  render() {
    return (
      <Panel dark title="JIRA Credentials">
        <CredentialsContainer>
          <input
            type="text"
            name="host"
            onChange={this.onChange}
            value={this.state.host}
            placeholder="Host (https://myjira.atlassian.net)"
          />
          <input
            type="text"
            name="user"
            onChange={this.onChange}
            value={this.state.user}
            placeholder="User (email.address@company.com)"
          />
          <input
            type="password"
            name="pass"
            onChange={this.onChange}
            value={this.state.pass}
            placeholder="Password"
          />
        </CredentialsContainer>
      </Panel>
    );
  }
}
