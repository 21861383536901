// @flow

import * as React from "react";
import styled from "styled-components";
import { LS_JQL_SNIPPET_KEY, DEFAULT_JQL } from "../config";
import { JQL_HEIGHT } from "../style";
import { Panel } from "./panel";

const JQLEditable = styled.div.attrs({
  contentEditable: true,
  suppressContentEditableWarning: true,
  spellCheck: false
})`
  line-height: 1.8em;
  &:focus {
    outline: none;
  }
`;

export class JQLInput extends React.Component<{}, { jql: string }> {
  state = { jql: "" };

  constructor() {
    super();
    const jql = localStorage.getItem(LS_JQL_SNIPPET_KEY);

    if (jql) {
      this.state.jql = jql;
    } else {
      this.state.jql = DEFAULT_JQL;
    }
  }

  onChange = (event: SyntheticInputEvent<HTMLDivElement>) => {
    localStorage.setItem(LS_JQL_SNIPPET_KEY, event.target.innerText || "");
  };

  render() {
    return (
      <Panel title="JQL">
        <JQLEditable onInput={this.onChange}>{this.state.jql}</JQLEditable>
      </Panel>
    );
  }
}
